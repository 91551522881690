.flex.flex-none.items-center.justify-center.border.border-neutral-200.bg-white.dark\:border-neutral-700.dark\:bg-black.h-\[40px\].w-\[40px\].rounded-xl {
    background-color: transparent !important;
    border: none !important;
    padding: 0px !important;
}/* CSS Document */

.bg-gray-900 {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.text-green-600 {
    --tw-text-opacity: .2;
    color: rgb(22 163 74/var(--tw-text-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity)) !important;
    color: #ffffff !important;
	background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-white {
    --tw-bg-opacity: .2;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgb(250, 250, 250, 0.2) !important;
    color: #ffffff !important;
}

.text {
    color: #ffffff !important;
}

h4 {
    color: #ffffff !important;
}

.lg\:block {
    display: block;
    width: 25% !important;
}

.flex {
    display: flex;
    overflow: hidden;
}

.xl\:block {
    display: block;
    width: 25% !important;
    vertical-align: top !important;
}

.xl\:gap-\[20px\] {
    gap: 20px;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-radius: 10px !important;
    padding: 15px !important;
}

.css-1ug174f {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}

.top-24 {
    top: 0 !important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: .7rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(250, 250, 250);
    font-weight: 500;
}

.text-\[\#333\] {
    --tw-text-opacity: 1;
    color: rgb(51 51 51/var(--tw-text-opacity));
    color: #ffffff !important;
}

.css-1pqb849 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(250, 250, 250) !important;
}

.css-zevtcw {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(70, 211, 0) !important;
}

.buzzweed-green {
    color: rgb(70, 211, 0) !important;
}

.css-pg6ig3 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(70, 211, 0) !important;
    margin: 0.5rem 0px;
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    /* border-bottom: rgb(70, 211, 0) !important; */
}

.text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74/var(--tw-text-opacity));
    background-color: rgba(250, 250, 250, 0.2);
}

.cart-icon {
    color: rgb(250, 250, 250) !important;
}

.text-\[\#68d284\] {
    --tw-text-opacity: 1;
    color: rgb(250 250 250/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    border-bottom: 3px solid rgb(70, 211, 0) !important;
}

.text-sm {
    font-size: 1.0rem !important;
    line-height: 1.25rem;
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 {
    color: rgb(70, 211, 0) !important;
    border-bottom: none !important;
}

.border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74/var(--tw-border-opacity));
    border-color: rgb(70, 211, 0) !important;
}

.bg-\[\#3bc177\] {
    --tw-bg-opacity: 1;
    background-color: rgb(59 193 119/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

svg.absolute.right-2.top-4.cursor-pointer {
    color: rgb(250, 250, 250) !important;
}

path {
    color: rgb(250, 250, 250) !important;
}

.border-\[\#3957db\] {
    --tw-border-opacity: 1;
    border-color: rgb(57 87 219/var(--tw-border-opacity));
    border-radius: 50px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
}

.border-\[\#3957db\] {
    --tw-border-opacity: 1;
    border-color: rgb(57 87 219/var(--tw-border-opacity));
    border-radius: 50px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
    color: rgb(250, 250, 250) !important;
}

path {
    color: rgba(250, 250, 250, 0.5) !important;
}

svg.absolute.right-2.top-1\.5.cursor-pointer {
    width: 25px !important;
    height: auto !important;
}

.bg-\[\#fff\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -25px !important;
}

h3.m-3.cursor-pointer.select-none {
    color: rgb(250, 250, 250) !important;
}

.pb-4.w-\[270px\].bg-\[\#fff\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.5) !important;
}

.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}


/* Comment Box */
.css-qiux0o {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.87) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 2rem;
    padding: 1rem 2rem;
}

/* Post Button */

.css-cda2fy.Mui-disabled {
    color: rgba(250, 250, 250, 1.0);
}

.css-cda2fy {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(40, 158, 49);
    background-color: rgb(70, 211, 49) !important;
    border-radius: 3rem;
}

/* Add Friend Button */
.css-ozo1r7 {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    color: rgb(250, 250, 250) !important;
}

.css-11gap41 {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(70, 211, 0) !important;
    padding: 0.6rem;
}

/* Posted Content Blocks */

.css-1l1ah9e {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 0.75rem;
}

body {
    color: rgb(250, 250, 250) !important;
}

.css-1ug174f {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.top-8 {
    top: 0 !important;
}

.text-\[\#3a24db\] {
    --tw-text-opacity: 1;
    color: rgb(58 36 219/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.border-\[\#3a24db\] {
    --tw-border-opacity: 1;
    border-color: rgb(58 36 219/var(--tw-border-opacity));
    border: none !important;
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
}

.rounded-\[5px\] {
    border-radius: 50px !important;
}

input.w-full.border.p-1.rounded-\[5px\].\!w-\[95\%\].mb-4.\38 00px\:mb-0 {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: none !important;
    padding: 10px !important;
}

input.w-full.border.p-1.rounded-\[5px\].\!w-\[95\%\].mb-1.\38 00px\:mb-0 {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: none !important;
    padding: 10px !important;
}

.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
}

.text-\[red\] {
    --tw-text-opacity: 1;
    color: rgb(255 0 0/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    font-weight: 500 !important;
}

.css-1rt5dsz {
    margin: 0px 0px 1.5rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgba(250, 250, 250, 0.7) !important;
    font-weight: 500;
}

label.block.pb-2 {
    color: rgba(250, 250, 250, 0.7) !important;
}