@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background: #000000;
    scroll-behavior: smooth;
}
input,select{
  outline: none;
}
/* .batch_heart{
  background: rgb(70, 211, 0) !important;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/*---------------CUSTOM CSS---------------*/


/* Ryan's CSS additions */
.reshare-container {
    margin: 10px 0;
  }
  
  .reshare-textarea {
    width: 100%;
    min-height: 80px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 10px; 
    background-color: #333; 
    color: white; 
    resize: vertical; 
  }
  
  .reshare-textarea:focus {
    border-color: #46d300; 
  }
  
  .reshare-button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #026506;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reshare-button:hover {
    background-color: #14b506;
  }

  .cancel-button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #a0a0a0; /* A neutral grey color */
    color: white;
    cursor: pointer;
    margin-left: 10px; /* Optional: Add some space between the Share and Cancel buttons */
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: #808080; /* A darker shade of grey for hover effect */
  }
  
  .cart-popup {
    position: fixed;
    top: 98px !important; /* Added margin with !important */
    right: 0;
    height: calc(100% - 20px); /* Adjusted for the margin */
    width: 80%;
    background-color: #2a2a2ad1 !important;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06); /* Adjust shadow-sm as needed */
  }
  
  /* Media query for screens wider than 800px */
  @media (min-width: 800px) {
    .cart-popup {
      width: 25%;
    }
  }
  
  .sponsored-dashboard {
    color: #fff;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    max-width: 98%;
    margin: 20px auto;
}

.dashboard-title {
    text-align: center;
    margin-bottom: 30px;
}

.sponsored-table {
    width: 100%;
    border-collapse: collapse;
}

.sponsored-table th, .sponsored-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #555;
}

.sponsored-table th {
    background-color: #444;
}

.sponsored-table tr:hover {
    background-color: #555;
}

.view-total-button {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
}

.view-total-button:hover {
    background-color: #0056b3; /* A darker shade of the primary color for hover effect */
}

.view-total-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); /* Bootstrap focus shadow style */
}

/* Daily Ad Graph Button */
.daily-ad-graph-button {
    padding: 10px 20px;
    margin-right: 5px; /* Provide spacing between buttons */
    font-size: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    background-color: #4CAF50; /* Example color */
    color: white; /* Text color */
}

/* Hover and Active States for Daily Button */
.daily-ad-graph-button:hover, .daily-ad-graph-button:focus {
    background-color: #45a049; /* Darker shade for hover */
}

/* Hourly Ad Graph Button */
.hourly-ad-graph-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    background-color: #008CBA; /* Example color */
    color: white; /* Text color */
}

/* Hover and Active States for Hourly Button */
.hourly-ad-graph-button:hover, .hourly-ad-graph-button:focus {
    background-color: #007bb5; /* Darker shade for hover */
}

/* Disabled State for Both Buttons */
.daily-ad-graph-button:disabled,
.hourly-ad-graph-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    border-color: #bbbbbb;
}

/* Filter graph Buttons */
.filter-button {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.filter-button:hover {
    background-color: #0056b3;
}

.filter-button:active {
    background-color: #004085;
    transform: translateY(2px);
}

.filter-button:focus {
    box-shadow: 0 0 0 3px rgba(0,123,255,.5);
}



/* Brand */

/* BrandCreate.module.css */
.brand-formContainer {
    max-width: 500px;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
  }
  
  .brand-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .brand-input,
  .brand-textarea,
  .brand-fileInput {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .brand-textarea {
    height: 100px;
  }
  
  .brand-button {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .brand-button:hover {
    background-color: #0056b3;
  }

  .catalog-create-modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    background-color: #353535de;
    border: 2px solid #000;
    max-height: 90vh; 
    overflow-y: auto; 
    box-shadow: 24px;
    padding: 4px;
}
  

.locator-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .locator-modal-content {
    background-color: rgb(55, 55, 55);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .locator-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #00ff2a;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .locator-button:hover {
    background-color: #0056b3;
  }
  /* Shipping */
  .shipping-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #444444;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.region-select-field {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.shipping-form label {
    display: block;
    color: #333;
    margin-bottom: 5px;
}

.shipping-form input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
}

.shipping-form input:focus {
    border-color: #4CAF50; 
}

.shipping-form button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #4CAF50; 
    cursor: pointer;
    transition: background-color 0.3s;
}

.shipping-form button:hover {
    background-color: #45a049; 
}

.shipping-form button[type="button"] {
    background-color: #888; 
}

.shipping-form button[type="button"]:hover {
    background-color: #666; 
}

.shipping-container {
    padding: 20px;
    background-color: #323232;
    min-height: 100vh;
    min-width: 80vw;
}

.shipping-rules-list {
    margin-top: 20px;
    border-top: 1px solid #e1e1e1;
    padding-top: 20px;
}

.shipping-rule-item {
    display: flex;
    flex-direction: column;
    background-color: #444444;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 10px;
    padding: 10px;
}

.shipping-rule-item > div {
    margin-bottom: 5px;
}

.shipping-rate-conditions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    background-color: #444444;
    border-radius: 5px;
}

.shipping-rate-conditions div {
    margin: 0 10px;
}

.shipping-rate-conditions h4, .shipping-rate-conditions h5 {
    margin-top: 0;
}

.rule-actions {
    display: flex;
    justify-content: flex-end;
}

.rule-actions button {
    margin-left: 10px;
}


.add-rule-btn, .edit-rule-btn, .delete-rule-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-rule-btn {
    background-color: #5c67f2;
}

.edit-rule-btn {
    background-color: #4CAF50;
}

.delete-rule-btn {
    background-color: #f44336;
}

.add-rule-btn:hover {
    background-color: #4a52d1;
}

.edit-rule-btn:hover, .delete-rule-btn:hover {
    opacity: 0.9;
}

.region-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-width: 400px;
    margin: auto;
}

.region-form input[type="text"], .region-form button, .region-form .select__control {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #323232;
}

/* Styling for React Select */
.region-form .select__control {
    display: flex;
    border-color: #ccc;
    box-shadow: none; /* Removes focus shadow */
    background-color: #323232;
}

.region-form .select__control--is-focused {
    border-color: #0056b3;
    box-shadow: none; /* Consistent focus styling */
}

.region-form .select__menu {
    background-color: #535353;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.region-form .select__option--is-focused {
    background-color: #323232;
    color: white;
}

.region-form .select__option--is-selected {
    background-color: #323232;
    color: white;
}

.region-form .select__multi-value {
    background-color: #323232;
    color: white;
}

.region-form .select__multi-value__remove:hover {
    background-color: #323232;
    color: white;
}

.region-form button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.region-form button.cancel-button {
    background-color: #f44336;
}

.region-form input[type="text"]:focus {
    border-color: #0056b3;
    outline: none;
}

.region-form button:hover {
    opacity: 0.9;
}


.regions-container {
    padding: 20px;
    background: #323232;
    border-radius: 8px;
    min-width: 80vw;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.region-list {
    list-style: none;
    padding: 0;
}

.region-item {
    background: #505050;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.region-actions {
    display: flex;
    gap: 10px;
}

.regions-button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.regions-button-edit {
    background-color: #007BFF;
    color: white;
}

.regions-button-edit:hover {
    background-color: #0056b3;
}

.regions-button-delete {
    background-color: #dc3545;
    color: white;
}

.regions-button-delete:hover {
    background-color: #c82333;
}

.regions-button-add {
    background-color: #6c757d;
    color: white;
}

.regions-button-add:hover {
    background-color: #5a6268;
}

/* subscription checkout section */
.subscription-details {
    background-color: #015e07; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin-top: 20px;
  }
  
  .subscription-details h2 {
    color: #ffffff; 
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .subscription-details p {
    color: #ffffff; 
    font-size: 16px;
    line-height: 1.5;
  }

  .change-subscription-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.change-subscription-modal-content {
    background-color: #3b3b3b;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}

.change-subscription-close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.change-subscription-close-btn:hover,
.change-subscription-close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.change-subscription-plan {
    padding: 10px;
    cursor: pointer;
}

.change-subscription-plan-current {
    background-color: lightgreen;
}

.upgrade-plan-btn {
    background-color: #4A90E2; 
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .upgrade-plan-btn:hover {
    background-color: #357ABD; 
  }

  .spm-container {
    max-width: 690px;
    margin: auto;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    background: #333;
}

.spm-form {
    display: flex;
    flex-direction: column;
}

.spm-card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
}

.spm-submit-btn {
    padding: 10px 15px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.spm-submit-btn:hover {
    background-color: #0056b3;
}

.spm-submit-btn:disabled {
    background-color: #ccc;
}

.spm-payment-details {
    margin-top: 20px;
    padding: 10px;
    background: #333;
    color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.spm-details-heading {
    margin-bottom: 10px;
    color: #333;
}

.spm-details-brand,
.spm-details-last4,
.spm-details-expiry {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
}

.spm-open-modal-btn {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.spm-open-modal-btn:hover {
    background-color: #0056b3;
}

.boost-animation-container {
    position: fixed; 
    top: 50%;       
    left: 50%;      
    transform: translate(-50%, -50%); 
    z-index: 1000;  
    width: 100%;    
    height: 100%;   
    display: flex;  
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}
 /* Tag manager */

 .admin-tag-management {
    padding: 20px;
    background-color: #333;
    border-radius: 8px;
    width: 42vw;
    max-width: 80vw;
    margin: auto;
}

.admin-heading {
    text-align: center;
    color: #333;
}

.tag-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.tag-input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #333;
    border-radius: 4px;
}

.tagbtn {
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}

.tagbtn-add-tag {
    background-color: #007BFF;
    color: white;
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tagbtn-add-tag:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

.tagbtn-add-tag:active {
    background-color: #004085;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
}

.tagbtn-add-tag:focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}


.tagbtn-approve {
    background-color: #28a745;
    color: white;
}

.tagbtn-delete {
    background-color: #dc3545;
    color: white;
}

.loading-text {
    text-align: center;
}

.tag-list {
    list-style-type: none;
    padding: 0;
    background-color: #333
}

.tag-item {
    background-color: #333;
    padding: 6px;
    border-radius: 4px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tag-name {
    display: inline-flex;
    align-items: center;
    gap: 2px; 
    border-radius: 0.375rem; 
    background-color: #38a169; 
    padding: 8px;
    font-size: 0.75rem; 
    font-weight: 500;
    color: white;
    box-shadow: inset 0 0 0 1px rgba(107, 114, 128, 0.1);
  }
  

.tag-info {
    flex-grow: 1;
}

.tag-selector .tag {
    margin: 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .tag-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #007BFF;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 999px; /* Highly rounded corners */
    margin: 5px;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    cursor: default;
  }
  
  .tag-badge button {
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .tag-badge button:hover {
    color: #ccc;
  }
  
  .tag-selector .tag-dropdown {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 10px;
    background-color: #636363;
    border:  1px solid #ccc;
    border-radius: 5px;
  }
  
  .tag-selector input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .tag-selector button {
    padding: 0.5rem;
    width: 100%;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .tag-selector button:hover {
    background-color: #218838;
  }
  
  .tag-badge.approved {
    background-color: green;
  }
  
  .tag-badge.unapproved {
    background-color: red;
  }
  
  .remove-tag-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    padding-left: 8px;
  }
  
  .remove-tag-button:hover {
    color: red; 
  }

  .tag-custom-input {
    width: 100%; 
    padding: 8px 12px; 
    margin: 10px 0; 
    box-sizing: border-box; 
    border: 2px solid #ccc; 
    border-radius: 4px; 
    background-color: #333; 
    font-size: 16px;
    color: #fff; 
}

.tag-custom-input:focus {
    border-color: green; 
    outline: none; 
    box-shadow: 0 0 8px rgba(44, 204, 0, 0.533); 
}
/* Suggested Friend Card */

.friend-card {
    margin: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    width: 150px;
    display: flex;
    flex-direction: column;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.suggested-friend-list {
    display: flex;
    flex-wrap: nowrap; 
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 8%;
    margin-right: 8%;
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch; 
  }

.friend-card-image img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.friend-card-content {
    padding: 8px;
    text-align: center;
}

.friend-card-name {
    margin-top: 6px;
    font-size: 16px;
    color: #333;
}

.friend-card-actions {
    display: flex;
    border-top: 1px solid #ccc;
    align-items: center;
}

.friend-card-action-email, .friend-card-action-call {
    flex: 1;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    color: #0056b3;
    font-weight: bold;
    border-right: 1px solid #ccc;
}

.friend-card-action-call {
    border-right: none;
}

.friend-card-action-email:hover, .friend-card-action-call:hover {
    background-color: #f0f0f0;
}


 /* Responsive design for smaller screens */
 @media (max-width: 768px) {
    .subscription-details {
      background-color: #015e07; 
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
  }

/* Styling for the list containing subscription details */
.user-subscriptions-list {
    list-style: none; 
    padding: 0;
    margin: 0;
}

.user-subscriptions-item {
    background-color: #3f3f3f; 
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px; 
    border-radius: 5px; 
}

.user-subscriptions-detail {
    font-size: 16px;
    color: #ffffff; 
    margin: 5px 0; 
}

.user-subscriptions-cancel-btn {
    background-color: #f44336; 
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    display: inline-block; 
    margin-top: 10px; 
}

.user-subscriptions-cancel-btn:hover {
    background-color: #d32f2f; 
}

/* Additional styling for active subscription management */
.active-subscription-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px; 
}

.active-subscription-button {
    background-color: #4CAF50; 
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
}

.active-subscription-button:hover {
    background-color: #388E3C; 
}


/* CSS Document */

.bg-gray-900 {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.text-green-600 {
    --tw-text-opacity: .2;
    color: rgb(22 163 74/var(--tw-text-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity)) !important;
    color: #ffffff !important;
	background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-white {
    --tw-bg-opacity: .2;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgb(250, 250, 250, 0.2) !important;
    color: #ffffff !important;
}

.text {
    color: #ffffff !important;
}

h4 {
    color: #ffffff !important;
}

.lg\:block {
    display: block;
    width: 25% !important;
}

.flex {
    display: flex;
}

.xl\:block {
    display: block;
    width: 25% !important;
    vertical-align: top !important;
}

.xl\:gap-\[20px\] {
    gap: 20px;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-radius: 10px !important;
    padding: 15px !important;
}

.css-1ug174f {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}

.top-24 {
    top: 0 !important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: .7rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(250, 250, 250);
    font-weight: 500;
}

.text-\[\#333\] {
    --tw-text-opacity: 1;
    color: rgb(51 51 51/var(--tw-text-opacity));
    color: #ffffff !important;
}

.css-1pqb849 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(250, 250, 250) !important;
}

.css-zevtcw {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(70, 211, 0) !important;
}

.buzzweed-green {
    color: rgb(70, 211, 0) !important;
}

.css-pg6ig3 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(70, 211, 0) !important;
    margin: 0.5rem 0px;
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    /* border-bottom: rgb(70, 211, 0) !important; */
}

.text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74/var(--tw-text-opacity));
    background-color: rgba(250, 250, 250, 0.2);
}

.cart-icon {
    color: rgb(250, 250, 250) !important;
}

.text-\[\#68d284\] {
    --tw-text-opacity: 1;
    color: rgb(250 250 250/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    border-bottom: 3px solid rgb(70, 211, 0) !important;
}

.text-sm {
    font-size: 1.0rem !important;
    line-height: 1.25rem;
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 {
    color: rgb(70, 211, 0) !important;
    border-bottom: none !important;
}

.border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74/var(--tw-border-opacity));
    border-color: rgb(70, 211, 0) !important;
}

.bg-\[\#3bc177\] {
    --tw-bg-opacity: 1;
    background-color: rgb(59 193 119/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

svg.absolute.right-2.top-4.cursor-pointer {
    color: rgb(250, 250, 250) !important;
}

path {
    color: rgb(250, 250, 250) !important;
}

.border-\[\#3957db\] {
    --tw-border-opacity: 1;
    border-color: rgb(57 87 219/var(--tw-border-opacity));
    border-radius: 50px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
}

.border-\[\#3957db\] {
    --tw-border-opacity: 1;
    border-color: rgb(57 87 219/var(--tw-border-opacity));
    border-radius: 50px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
    color: rgb(250, 250, 250) !important;
}

path {
    color: rgba(250, 250, 250, 0.5) !important;
}

svg.absolute.right-2.top-1\.5.cursor-pointer {
    width: 25px !important;
    height: auto !important;
}

.bg-\[\#fff\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -25px !important;
}

h3.m-3.cursor-pointer.select-none {
    color: rgb(250, 250, 250) !important;
}

.pb-4.w-\[270px\].bg-\[\#fff\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.5) !important;
}

.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}



/*--------------2nd Update--------------*/

/* Comment Box */
.css-qiux0o {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.87) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 2rem;
    padding: 1rem 2rem;
}

/* Post Button */

.css-cda2fy.Mui-disabled {
    color: rgba(250, 250, 250, 1.0);
}

.css-cda2fy {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(40, 158, 49);
    background-color: rgb(70, 211, 49) !important;
    border-radius: 3rem;
}

/* Add Friend Button */
.css-ozo1r7 {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    color: rgb(250, 250, 250) !important;
}

.css-11gap41 {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(70, 211, 0) !important;
    padding: 0.6rem;
}

/* Posted Content Blocks */

.css-1l1ah9e {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 0.75rem;
}

body {
    color: rgb(250, 250, 250) !important;
}

.css-1ug174f {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.top-8 {
    top: 0 !important;
}

.text-\[\#3a24db\] {
    --tw-text-opacity: 1;
    color: rgb(58 36 219/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.border-\[\#3a24db\] {
    --tw-border-opacity: 1;
    border-color: rgb(58 36 219/var(--tw-border-opacity));
    border: none !important;
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
}

.rounded-\[5px\] {
    border-radius: 50px !important;
}

input.w-full.border.p-1.rounded-\[5px\].\!w-\[95\%\].mb-4.\38 00px\:mb-0 {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: none !important;
    padding: 10px !important;
}

input.w-full.border.p-1.rounded-\[5px\].\!w-\[95\%\].mb-1.\38 00px\:mb-0 {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: none !important;
    padding: 10px !important;
}

.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
}

.text-\[red\] {
    --tw-text-opacity: 1;
    color: rgb(255 0 0/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    font-weight: 500 !important;
}

.css-1rt5dsz {
    margin: 0px 0px 1.5rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgba(250, 250, 250, 0.7) !important;
    font-weight: 500;
}

label.block.pb-2 {
    color: rgba(250, 250, 250, 0.7) !important;
}



/*---------UPDATE 3--------*/

.css-1fzwgfs {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.7) !important;
}

.css-76kev2 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.7) !important;
}

.w-\[35px\] {
    width: 35px;
    height: auto;
}




/*------------Update 4----------------*/

.w-\[90\%\].\38 00px\:w-\[60\%\].h-\[90vh\].overflow-y-scroll.\38 00px\:h-\[75vh\].bg-white.rounded-md.shadow-sm.relative.p-4 {
    background-color: rgba(0,0,0,0.8) !important;
    padding: 25px !important;
    color: rgb(250, 250, 250) !important;
}

.w-full.\38 00px\:w-\[50\%\].pt-5.pl-\[5px\].pr-\[5px\] {
    padding: 25px !important;
}

aside.sticky.top-24.hidden.w-44.shrink-0.lg\:block.bg-\[\#f5f5f5\].p-2.rounded-lg {
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    background-color: rgba(0, 0, 0, 0.8) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-10yzw17 {
    gap: 0.25rem;
    display: inline-block !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 22%;
}

.css-cda2fy.Mui-disabled {
    color: rgba(250, 250, 250, 1.0) !important;
}

.css-1sb2j9v {
    gap: 1rem;
    display: inline-block;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.css-11gap41 {
    display: inline-block;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(27, 242, 73);
    padding: 0.6rem;
    position: relative;
    width: 45px !important;
    height: 45px !important;
    float: right;
}

.css-1sb2j9v {
    gap: 1rem;
    display: inline-block;
    position: relative !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    /* float: left; */
}

.css-1g4v7g4 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(250, 250, 250) !important;
    font-weight: 500;
}

.border-\[2px\] {
    border-width: 1px !important;
}

.css-qiux0o {
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-ei7csf {
    width: 45px !important;
    height: auto !important;
    color: rgb(250, 250, 250) !important;
    padding-top: 15px !important;
}

hr.MuiDivider-root.MuiDivider-fullWidth.css-1drwh4k {
    margin-top: 25px !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
}


/*-------Update 5----------*/

button.text-gray-500.border-transparent.hover\:text-gray-700.whitespace-nowrap.py-4.px-1.border-b-2.font-medium.text-sm {
    text-align: center !important;
    margin: auto !important;
}

input.MuiInputBase-input.css-mnn31 {
    background: transparent !important;
}

.MuiBox-root.css-dlj3h8 {
    display: inline-block !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    border-radius: 0 !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    border-radius: 0 !important;
    background-color: rgba(250, 250, 250, 0.3) !important;
}

.bg-\[\#56d879\] {
    --tw-bg-opacity: 1;
    background-color: rgb(86 216 121/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

.text-\[\#56d879\] {
    --tw-text-opacity: 1;
    color: rgb(86 216 121/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    border-radius: 50px !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding-left: 25px !important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    border-radius: 50px !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding-left: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

button.bg-\[\#56d879\].hover\:bg-teal-500.duration-300.px-5.py-2\.5.rounded-md.text-whie.md\:w-auto.w-full {
    border-radius: 50px !important;
}

input.h-\[40px\].w-full.px-2.border-\[\#3957db\].border-\[2px\].rounded-md {
    padding-left: 25px !important;
}

h5.pt-3.text-\[15px\].text-blue-400.pb-3 {
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin-top: 15px !important;
}

.bg-\[\#f5f6fb\].px-3.\38 00px\:px-10.py-2.rounded {
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
    border-radius: 15px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.text-\[\#000\] {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

svg.absolute.right-2.top-1\.5.cursor-pointer {
    width: 25px !important;
    height: auto !important;
    margin-right: 10px !important;
}

.pb-4.w-\[270px\].bg-\[\#fff\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}

.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61/var(--tw-bg-opacity));
    border-radius: 50px !important;
}

button.bg-gradient-to-r.from-teal-400.to-teal-500.text-white.font-bold.rounded-l.px-4.py-2.shadow-lg.hover\:opacity-75.transition.duration-300.ease-in-out {
    border-radius: 0px !important;
    /* background-color: rgb(0,0,0,0.7) !important; */
    border: 1px solid rgb(70, 211, 0) !important;
}

.text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
    font-size: 20px !important;
    text-transform: capitalize !important;
}

h1.text-\[25px\].font-\[600\].font-Roboto.text-\[\#333\] {
    text-transform: capitalize !important;
}

.css-5vij8j {
    color: rgb(250, 250, 250) !important;
}


/*------Update 6----------*/

.bg-white {
    --tw-bg-opacity: .2;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgb(250, 250, 250, 0.2) !important;
    color: #ffffff !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -29px !important;
}

.MuiBox-root.css-1ug174f {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.w-\[50\%\].relative {
    width: 25% !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-ei7csf {
    width: 25px !important;
    height: auto !important;
    color: rgb(250, 250, 250) !important;
    padding-top: 15px !important;
    text-align: center !important;
}

aside.sticky.top-24.hidden.w-44.shrink-0.lg\:block.bg-\[\#f5f5f5\].p-2.rounded-lg {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-1l1ah9e {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-gray-900 {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-left: 0px !important;
}

.share-button {
    align-items: center;
    border-radius: 50px;
    display: flex;
    font-size: 18px !important;
    padding: 20px;
    text-decoration: none;
    transition: background-color .3s ease;
    width: 45px !important;
    height: 45px !important;
    text-align: center !important;
    margin-top: 25px !important;
}

h4.text-\[\#000000a6\] {
    text-transform: capitalize !important;
}

.md\:justify-between {
    justify-content: space-evenly !important;
}

h1.lg\:text-4xl.text-3xl.md\:mb-0.mb-6.lg\:leading-normal.font-semibold.md\:w-2\/5 {
    float: right !important;
    display: inline-block !important;
    position: relative !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
    text-align: right !important;
}

/*Profile Pic*/
img.w-\[150px\].h-\[150px\].object-cover.rounded-full {
    border: 2px solid rgba(2500, 250, 250, 1.0) !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-11gap41 {
    display: none !important;
}

/*.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10.sm\:pl-16 {
    top: 100px !important;
}*/

.bg-\[\#E3E9EE\] {
    --tw-bg-opacity: .7 !important;
    background-color: rgb(0 0 0/var(--tw-bg-opacity)) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -29px!important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.w-\[42\%\] {
    width: 25%;
}
.pb-4.pt-16.w-\[270px\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}

button.h-full.w-full.flex.justify-between.items-center.pl-10.bg-white.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    z-index: 1000000 !important;
}

.border-\[\#3957db\], svg.absolute.right-2.top-4.cursor-pointer {
    color: #fafafa!important;
    z-index: 100000000 !important;
}

/*All Paths Z-Index*/
path {
    color: inherit !important;
    z-index: 2000000 !important;
}

path, svg.Posts_absolute__OyiG3.Posts_right-2__Y4Tfe.Posts_top-4__rhvX1.Posts_cursor-pointer__pcF7\+ {
    color: inherit !important;
}

.border-\[\#46d300\] {
    --tw-border-opacity: 1;
    border-color: rgb(70 211 0/var(--tw-border-opacity));
    border-color: rgba(250, 250, 250, 0.3) !important;
}

.py-5 {
    padding-bottom: 3.25rem !important;
    padding-top: 3.25rem !important;
}

.border.cursor-pointer {
    margin-top: 25px !important;
    border: 1px solid #ffffff !important;
    padding: 15px !important;
}

.mt-3 {
    margin-top: 0rem;
}
.mr-3 {
    margin-right: 0rem;
}

.border.cursor-pointer {
    margin-top: 45px !important;
    margin-right: 15px !important;
    border: 1px solid #ffffff !important;
    padding-top: 0px !important;
    padding: 15px !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -30px!important;
    margin-left: 15px!important;
    margin-right: 15px!important;
    border-bottom: none !important;
}

/*Add Prod Button*/



.h-\[200px\] {
    height: 100px !important;
}

.w-\[150px\].bg-green-700.h-\[50px\].my-3.flex.items-center.justify-center.rounded-xl.cursor-pointer.bg-\[\#6443d1\].mt-4.\!rounded.\!h-11 {
    background-color: #777777 !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

button.bg-gradient-to-r.from-teal-400.to-teal-500.text-white.font-bold.rounded-l.px-4.py-2.shadow-lg.hover\:opacity-75.transition.duration-300.ease-in-out {
    border-radius: 24px;
    margin-left: 15px !important;
    margin-right: 15px !important;
    font-size: 16px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    background-image: none !important;
    border-color: rgba(250, 250, 250, 0.2) !important;
    border: 2px solid rgba(250, 250, 250, 0.2) !important;
}

.ml-1 {
    margin-left: 0.25rem;
    font-size: 25px !important;
}

path {
    color: rgba(250, 250, 250, 0.8) !important;
}

.bg-\[\#e44343\] {
    --tw-bg-opacity: 1;
    background-color: rgb(228 67 67/var(--tw-bg-opacity));
    background-color: rgb(70,211,0) !important;
}

.text-\[16px\] {
    font-size: 16px;
    color: #ffffff !important;
    text-transform: capitalize !important;
}

.bg-\[\#f63b60\] {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

.\!bg-\[\#FDE1E6\] {
    --tw-bg-opacity: .5 !important;
    background-color: rgb(225 225 225/var(--tw-bg-opacity))!important;
}

.\!text-\[\#f63b60\] {
    --tw-text-opacity: 1!important;
    color: rgb(225 225 225/var(--tw-text-opacity))!important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    background-color: rgba(0,0,0,.5)!important;
    border: 1px solid hsla(0,0%,98%,.3)!important;
    border-radius: 50px!important;
    padding-left: 25px!important;
    color: #ffffff !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 path {
    color: rgb(70, 211, 0) !important;
    border-bottom: none !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 > path {
    border-bottom: none!important;
    color: #46d300!important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgba(250, 250, 250, 0.5) !important;
    font-weight: 500;
}

/*-----Dashboard-------*/

/*.w-full.h-\[80px\].bg-white.shadow.sticky.top-0.left-0.z-30.flex.items-center.justify-between.px-4 div a > img {
    width: 37% !important;
    height: auto !important;
}*/

.text-\[\#555\] {
    --tw-text-opacity: 1;
    color: rgb(85 85 85/var(--tw-text-opacity));
    color: rgba(250, 250, 250, 0.9) !important;
}

.text-\[crimson\] {
    --tw-text-opacity: 1;
    color: rgb(220 20 60/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
}

/*Ellipsis*/

/*.css-mh3zap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: var(--unstable_DataGrid-headWeight);
}*/

.MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-78d1bb {
    color: #ffffff !important;
}

.css-78d1bb {
    --unstable_DataGrid-radius: 4px;
    --unstable_DataGrid-headWeight: 500;
    --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
    --DataGrid-cellOffsetMultiplier: 2;
    flex: 1 1 0%;
    box-sizing: border-box;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-radius: var(--unstable_DataGrid-radius);
    color: rgba(250, 250, 250, 1.0) !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    display: flex;
    min-width: 0px;
    min-height: 0px;
    flex-direction: column;
    overflow-anchor: none;
}

p#\:r4p\: {
    color: rgba(250, 250, 250, 0.9) !important;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
    margin: 0;
    color: rgba(250, 250, 250, 0.9) !important;
}

.css-78d1bb.MuiDataGrid-autoHeight {
    height: auto;
}
.css-78d1bb {
    --unstable_DataGrid-radius: 4px;
    --unstable_DataGrid-headWeight: 500;
    --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
    --DataGrid-cellOffsetMultiplier: 2;
    flex: 1 1 0%;
    box-sizing: border-box;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-radius: var(--unstable_DataGrid-radius);
    color: rgba(250, 250, 250, 1.0) !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    display: flex;
    min-width: 0px;
    min-height: 0px;
    flex-direction: column;
    overflow-anchor: none;
    padding: 25px !important;
}

.w-full.mx-8.pt-1.mt-10.bg-white {
    padding: 25px !important;
}

.w-full.min-h-\[45vh\].bg-white.rounded {
    padding: 25px !important;
}

.share-buttons {
    display: flex;
    justify-content: space-evenly !important;
}

.share-button {
    align-items: center;
    border-radius: 50px !important;
    display: flex;
    font-size: 18px !important;
    padding: 15px !important;
    text-decoration: none;
    transition: background-color .3s ease;
}

.inner-container {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.1) !important;
}

.outer-container {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.relative.min-h-\[70vh\].\38 00px\:min-h-\[80vh\].w-full.bg-no-repeat.flex.items-center {
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.w-full.h-\[420px\].rounded-lg.shadow-sm.p-3.relative.cursor-pointer {
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    padding-top: 25px !important;
}


.inner-container {
background-color: rgba(250, 250, 250, 0.0) !important;
	border: 0px !important;
}

input.w-full.border.p-1.rounded-\[5px\] {
    background-color: rgb(45,45,45) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.3) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}


/*------Messaging------*/
/*.text-\[\#fff\], .text-\[\#ffffff\] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: block !important;
    position: relative !important;
    padding: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
}

.text-\[\#fff\], .text-\[\#ffffff\] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: block !important;
    position: relative !important;
    padding: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border-radius: 14px !important;
}*/

.w-full.flex.p-3.px-3.bg-\[\#00000010\].cursor-pointer {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.w-full.flex.p-3.px-3.bg-transparent.cursor-pointer {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

aside.outer-container div .p-3 {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

aside.outer-container div .p-3 h5 {
    font-size: 12px !important;
}

h5.font-\[600\] {
    font-size: .2em !important;
	color: rgba(250, 250, 250, 0.4) !important;
}

.profile-dropdown {
    z-index: 1000000 !important;
    overflow: inherit !important;
}

.hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity)) !important;
}

div#headlessui-menu-items-\:rq\: {
    display: block;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: rgb(250, 250, 250) !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

a#headlessui-menu-item-\:rr\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rs\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rt\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

/*body {
    background: rgba(14, 17, 22, 0.7) !important;
}*/
/*----rgba(14, 17, 22, 0.7)-----*/

.css-1r1fc2u {
    background-color: rgba(250, 250, 250, 0.7) !important;
}

.css-1r1fc2u {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(74, 74, 74);
    border-radius: 0.75rem;
}

.css-elpoka {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: 0.75rem;
}


/*-----NUMBER SOLD---------*/
span.font-\[400\].text-\[17px\].text-\[\#68d284\] {
    display: none !important;
}


/*-----Cart Titles---------*/
h4.pb-3.font-\[500\].items-center {
    text-transform: capitalize !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    background-color: rgba(14, 17, 22, 0.7) !important;
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
    /* border-bottom: rgba(250, 250, 250, 0.2) !important; */
}

.grid.grid-cols-1.sm\:gird-cols-3.lg\:grid-cols-4.gap-6.sm\:px-8.px-5.py-16.sm\:text-center {
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.pb-4.pt-16.w-\[270px\].absolute.z-30.rounded-b-md.shadow-sm {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
}

h1.text-\[\#fff\].text-\[18px\].font-\[600\] {
    background-color: transparent !important;
    border: 0px !important;
}

.text-\[\#475ad2\] {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}


.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

a.bg-indigo-500.text-white.shadow-sm.hover\:bg-green-400.focus-visible\:outline-green-500.mt-6.block.rounded-md.py-2.px-3.text-center.text-sm.font-semibold.leading-6.focus-visible\:outline.focus-visible\:outline-2.focus-visible\:outline-offset-2 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
}

.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border: 1px solid rgb(70, 211, 0) !important;
}

span.pr-3.font-\[400\].text-\[17px\].text-\[\#44a55e\] {
    display: none !important;
}

div#headlessui-menu-items-\:rb\: {
	position: fixed !important;
}

.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin-bottom: 25px !important;
}

@media (min-width: 1024px) {
    .lg\:px-24 {
      padding-left: 6rem;
      padding-right: 6rem;
      padding-top: 45px !important;
    }
  }


.min-h-screen.bg-gray-50.flex.flex-col.justify-center.py-12.sm\:px-6.lg\:px-8 {
    background-color: rgba(15, 17, 22, 1.0) !important;
}


h2.mt-6.text-center.text-3xl.font-extrabold.text-gray-900 {
    text-transform: capitalize !important;
}

label.block.text-sm.font-medium.text-gray-700 {
    text-transform: capitalize !important;
}

.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    width: 100% !important;
    max-width: 50% !important;
    margin: auto !important;
}

.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}

.mt-8.sm\:mx-auto.sm\:w-full.sm\:max-w-\[35rem\] .flex.items-center.w-full {
    text-align: center !important;
    margin: auto !important;
    display: block !important;
    position: relative !important;
    margin-top: 25px !important;
    margin-bottom: 15px !important;
}

input.appearance-none.block.w-full.px-3.py-2.border.border-gray-300.rounded-md.shadow-sm.placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    background-color: rgba(250, 250, 250, 0.5) !important;
    color: rgb(14, 17, 22) !important;
    border-radius: 50px !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39/var(--tw-text-opacity));
    color: rgba(250, 250, 250, 0.7) !important;
}

.relative.inline-block {
    float: right !important;
}

div#headlessui-popover-panel-\:r6\: {
    margin-top: 35px !important;
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}

button.text-green-500.border-green-500.whitespace-nowrap.py-4.px-1.border-b-2.font-medium.text-sm {
    border-bottom: 2px solid rgb(70, 211, 0) !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Mui-disabled.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Posts_css-cda2fy__xgrti.css-1ujsas3 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    color: rgb(250, 250, 250) !important;
    width: 20% !important;
}

.profile-dropdown {
    overflow: inherit!important;
    z-index: 1000000!important;
    display: inline-flex !important;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: auto !important;
}

a#headlessui-menu-item-\:r1\:,a#headlessui-menu-item-\:r2\:,a#headlessui-menu-item-\:r3\:,a#headlessui-menu-item-\:r4\: {
    background-color: rgba(0, 0, 0, 0.2) !important;
	border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a:hover#headlessui-menu-item-\:r1\:,a:hover#headlessui-menu-item-\:r2\:,a:hover#headlessui-menu-item-\:r3\:,a:hover#headlessui-menu-item-\:r4\: {
    background-color: rgba(0, 0, 0, 0.2) !important;
	border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.9rem !important;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(18, 66, 22);
    font-weight: 500;
}


/*Posts Top Title*/
h3.px-4.py-2.text-xl.font-semibold.text-gray-900 {
    display: none !important;
}


h3.text-center.py-2.text-\[20px\] {
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    display: none !important;
}


/*Profile Header*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    display: none !important;
}

img.w-\[150px\].h-\[150px\].object-cover.rounded-full {
    border: 2px solid snow!important;
    display: block!important;
    height: 95px!important;
    width: 95px!important;
}

#root .profile-page img.w-full.object-cover {
    height: 450px !important;
}


/* Cart Image */

img.w-\[130px\].h-min.ml-2.mr-2.rounded-\[5px\] {
    border-radius: 0px !important;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
}

svg.cursor-pointer.\38 00px\:mb-\[\'unset\'\].\38 00px\:ml-\[\'unset\'\].mb-2.ml-2 {
    margin-left: 0 !important;
}

.w-full.\38 00px\:flex.items-center {
    width: 100% !important;
}

.product-description {
	font-size: 16px;
	color: rgba(250, 250, 250, 0.8) !important;
    text-transform: capitalize !important;
}

.content-bg {
    background-color: rgba(14,17,22,.7) !important;
}

.bg-drk {
	background-color: rgba(14,17,22,.2) !important;
}

/*Search Dropdown*/
.search-drop {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-left: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
    width: 100% !important;
    border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-radius: 10px !important;
}


/*Categories Drop Down*/
button.h-full.w-full.flex.justify-between.items-center.pl-10.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    z-index: 2000000 !important;
}

.css-miw4ts {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: hsla(0,0%,98%,.2)!important;
    border: 1px solid hsla(0,0%,98%,.2)!important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 98px !important;
}

h3.text-xl.font-bold.text-green-500.sm\:text-2xl {
    color: #ffffff !important;
    line-height: 35px !important;
}

p.text-sm.text-green-500 {
    padding-bottom: 15px !important;
}

.profile-page .w-full {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-mnn31 {
    font: inherit;
    letter-spacing: inherit;
    color: rgb(250, 250, 250) !important;
    padding: 4px 0px 5px;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
}

.css-1r4ju6z {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.1);
    border-radius: 2rem;
    padding: 1rem 2rem;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-dejdd {
    width: 55px;
    height: 55px;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
    border-radius: 100% !important;
}

img.h-10.w-10.rounded-full {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.css-1sb2j9v {
    gap: 1rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    text-align: center !important;
}

.css-10yzw17 {
    gap: 0.25rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    text-align: center !important;
}

.MuiBox-root.css-dejdd>img {
    height: 55px !important;
    width: 55px !important;
}

.user-info.px-4.py-5.sm\:px-6 {
    width: 86% !important;
    height: 100px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin: auto !important;
    border-radius: 10px !important;
    margin-top: 35px !important;
    vertical-align: middle !important;
}

.mx-auto.flex.w-full.items-start.gap-x-8.px-4.py-10.sm\:px-6.lg\:px-24 {
    padding-top: 25px !important;
}

button.inline-flex.items-center.rounded-md.bg-green-600.px-3.py-2.text-sm.font-semibold.text-white.shadow-sm.hover\:bg-green-500 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    margin-bottom: 0px !important;
}

textarea.block.w-full.resize-none.border-0.bg-transparent.px-1\.5.py-1\.5.text-white.placeholder\:text-white.focus\:border-green-500.focus\:ring-0.sm\:text-sm {
    width: 100% !important;
}


.h-\[200px\] {
    height: 100px!important;
    width: 100px !important;
}




/*-----MOBILE--------*/

/* hi-res laptops and desktops */ 
@media (max-width:1281px) { 
	
	.py-1 {
    display: none !important;
}
	
	.null.w-full.h-\[60px\].bg-\[\#fff\].z-50.top-0.left-0.shadow-sm.\38 00px\:hidden {
    padding-top: 10px !important;
}
	
.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}	
	.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    color-interpolation: linearRGB;
    color: rgb(250, 250, 250) !important;
}
}

/* big landscape tablets, laptops, and desktops */
@media (max-width:1025px) {  }

/* tablet, landscape iPad, lo-res laptops ands desktops */ 
@media (max-width:961px)  { 
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 14px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 6.5em !important;
}
	.profile-main.-mt-12.px-4.sm\:-mt-8.sm\:flex.sm\:items-end.sm\:px-6.lg\:-mt-16.lg\:px-24 {
    text-align: center !important;
}
	.inline-flex.overflow-hidden.border-4.border-white {
    margin: auto !important;
}
	
	h3.text-xl.font-bold.text-green-500.sm\:text-2xl {
    color: #fff!important;
    line-height: 35px!important;
    text-align: center !important;
    margin: auto !important;
}
	
	.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}
}

@media (min-width:800px) and (max-width:995px)  { 
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 6.0em !important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 14px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
@media (max-width:641px)  { 
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 11px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.5em !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}


 /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
@media (max-width:481px)  {
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px;
    text-transform: capitalize !important;
    padding-bottom: 10px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.5em !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}



/* hi-res iphone */ 
@media only screen and (max-width: 480px){ 

}


/* hi-res iphone */ 
@media only screen and (max-width: 386px){ 
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.3em!important;
}
	
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px !important;
    margin-bottom: 10px!important;
    margin-top: 0!important;
    padding-bottom: 15px!important;
    padding-top: 0!important;
    text-transform: capitalize!important;
}
	
	.py-1 {
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
	
/*.null.w-full.h-\[60px\].bg-\[\#fff\].z-50.top-0.left-0.shadow-sm.\38 00px\:hidden {
    padding-top: 10px !important;
}*/
	
}


/* smartphones, iPhone, portrait 480x320 phones */ 
@media (max-width:320px)  { 
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.0em!important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px !important;
    margin-bottom: 10px !important;
    margin-top: 0!important;
    padding-bottom: 15px!important;
    padding-top: 0!important;
    text-transform: capitalize!important;
}
	
.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 45px !important;
}
}


.profile-dropdown {
    z-index: 1000000 !important;
    overflow: inherit !important;
}

.hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

div#headlessui-menu-items-\:rq\: {
    display: block !important;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: rgb(250, 250, 250) !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

a#headlessui-menu-item-\:rr\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rs\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rt\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}


.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 0.8) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

div#headlessui-menu-items-\:rb\: {
	position: fixed !important;
}

/*---- TEMP HIDE-----*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    /*display: none !important;*/
}

.css-1r1fc2u {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(74, 74, 74);
    border-radius: 0.75rem;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

/*----Profile Header--------*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    /*display: none !important;*/
}

.flex.h-full.flex-col.overflow-y-scroll.bg-white.shadow-xl {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.relative.MuiBox-root.css-b8gmgq {
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.relative.MuiBox-root.css-b8gmgq>img {
    border: 2px solid rgba(0, 0, 0, 0.3);
}

.profile-page .w-full {
    border-bottom: none !important;
}

.relative.cursor-pointer.mr-\[15px\] {
    z-index: 2000000 !important;
}

/*Login-*/

button.group.relative.w-full.h-\[40px\].flex.justify-center.py-2.px-4.border.border-transparent.text-sm.font-medium.rounded-md.text-white.bg-green-600.hover\:bg-green-700 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    width: 50% !important;
    margin: auto !important;
}

.text-green-700 {
    color: rgb(70, 211, 0) !important;
    line-height: 55px !important;
    border-bottom: none !important;
}

.mt-1 input.appearance-none.block.w-full.px-3.py-2.border.border-gray-300.rounded-md.shadow-sm.placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-green-500.sm\:text-sm {
    border-radius: 50px !important;
}

.mt-8.sm\:mx-auto.sm\:w-full.sm\:max-w-md {
    border-radius: 15px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 15px !important;
}

.profile-dropdown {
    border-bottom-right-radius: 0!important;
    border-top: 0!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    display: inline-flex!important;
    margin-top: 25px!important;
    position: fixed!important;
    width: auto!important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

/*.profile-dropdown a:hover, .profile-dropdown form button#headlessui-menu-item-\:r9\: {
    border-bottom-right-radius: 0!important;
    border-top: 0!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    display: inline-flex!important;
    margin-top: 25px!important;
    position: fixed!important;
    width: auto!important;
    background-color: rgba(70, 211, 270, 1.0) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}*/

.fixed.inset-x-0.bottom-0.z-100.bg-gray-700 {
    z-index: 10000 !important;
}

button.rounded-md.bg-green-600.px-3\.5.py-2\.5.text-sm.font-semibold.text-white.shadow-sm.hover\:bg-green-500.focus-visible\:outline.focus-visible\:outline-2.focus-visible\:outline-offset-2.focus-visible\:outline-indigo-600 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

a.flex.justify-center.items-center.px-6.py-2.border.border-transparent.text-base.font-medium.rounded-md.text-white.bg-indigo-600.hover\:bg-indigo-700.md\:py-2.md\:text-lg.md\:px-10 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    max-width: 50% !important;
    margin: auto !important;
    margin-bottom: 25px !important;
}


input.mt-2.appearance-none.block.w-full.px-3.h-\[35px\].border.border-gray-300.rounded-\[3px\].placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    color: #333333 !important;
}

textarea.mt-2.appearance-none.block.w-full.pt-2.px-3.border.border-gray-300.rounded-\[3px\].placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    color: #333333 !important;
}

.bg-\[\#099910\] {
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

#root .profile-page img.w-full.object-cover {
    height: 450px!important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.profile-toolbar-avatar {
    height: auto;
    width: 42px;
    border-radius: 100%;
    border: 1px solid #ffffff !important;
    margin-bottom: 15px !important;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10 {
    margin-top: 100px !important;
}

.relative.flex-1.px-4.py-6.sm\:px-6 .flow-root {
    overflow: scroll !important;
}

.css-1o5bsd6 {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 16px;
}
.css-1cbcpl3 {
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    margin-top: -16px;
    width: calc(100% + 16px);
    margin-left: -8px !important;
    background-color: rgba(250, 250, 250, 0.0) !important;
}

.css-fkcv64 {
    display: flex;
    width: 100%;
    border-top: 1px solid rgba(250, 250, 250, 0.12) !important;
}

/*Shop by seller component */
/* shopBySeller.css */
.shop-list {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping of items */
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 8%;
    margin-right: 8%;
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
  }
  
  .shop-card {
    width: 200px; /* You might want to adjust this for better mobile responsiveness */
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    flex-shrink: 0; /* Prevent cards from shrinking */
  }
  
  .shop-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .shop-card h3 {
    margin-top: 10px;
    color: #333;
  }
  
/* ShopProfile Tabs css */

.nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; 
    gap: 10px; 
  }
  
  .tab-button {
    flex: 0 0 auto; 
    white-space: nowrap;
  }
  


/* SponsorProduct.css */
.range-slider {
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #1f9c29;
    outline: none;
    opacity: 0.7;
    -webkit-appearance: none; /* Removes default styling for WebKit browsers */
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Removes default thumb for WebKit browsers */
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    border: 3px solid #1f9c29;
}

.range-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    border: 3px solid #1f9c29;
}

/* map.css */

.map-container {
    position: relative;
    width: 100vw;
    height: 80vh;
  }

  .filters-container {
    background: #212121d4;
    border: #14b506;
    border-radius: 8px;
    box-shadow: 0 2px 4px #0003;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    padding: 20px;
    position: absolute;
    right: 60px;
    top: 55%;
    width: 300px;
    z-index: 2;
}
  
  @media (max-width: 768px) {
    .filters-container {
      width: 90%;
      top: 10px;
      right: 10px;
      max-height: calc(100vh - 20px);
    }
  }